import { useState, useEffect } from "react"
import Auth from "@/aws/AmplifyAuthConfigure"
import { ButtonBase } from "@/components/NotarizationButton"
import GoBackButton from "@/components/GoBackButton"
import ReactInputVerificationCode from "react-input-verification-code"
import { ResendIcon } from "@/icons"
import styled from "styled-components"
const Confirm = styled.div`
  /* position: relative; */

  .ver-code {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 86px;
  }

  .ReactInputVerificationCode__container {
    width: auto;
    display: flex;
    justify-content: flex-start;
    gap: 4px;
  }
  .ReactInputVerificationCode__item {
    width: 35px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 130%;
    font-weight: 500;
    background: #ededed;
    color: #000000;
    border-radius: 2px;
  }
  .ReactInputVerificationCode__item,
  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
  }
  .ReactInputVerificationCode__item.is-active {
    border: 1px solid #ffc700;
  }

  @media (min-width: 768px) {
    .ReactInputVerificationCode__container {
      gap: 7px;
    }
    .ReactInputVerificationCode__item {
      min-width: 47px;
      height: 65px;
      font-size: 40px;
    }
  }
  @media (min-width: 1025px) {
    .ver-code {
      margin-bottom: 125px;
    }

    .ReactInputVerificationCode__container {
      gap: 14px;
    }
    .ReactInputVerificationCode__item {
      width: 60px;
      height: 82px;
      font-size: 50px;
    }
  }
`

const VerificationCode = ({ setCode, email, setStep }) => {
  const [err, setErr] = useState(false)
  const handleResend = async () => {
    try {
      await Auth.resendSignUp(email)
    } catch (err) {
      setErr("Error resending code")
    }
  }

  const handleConfirmSignUp = async code => {
    try {
      setCode(code)
      setStep(3)
    } catch (error) {
      setErr(error.message)
    }
  }

  useEffect(() => {
    let seto
    if (err) {
      seto = setTimeout(() => {
        setErr(false)
      }, 5000)
    }
    return () => clearTimeout(seto)
  }, [err])

  return (
    <Confirm>
      <GoBackButton onClick={() => setStep(1)} className="goBackButton" />
      <p className="err-backend">{err && `\u002A ${err}`}</p>
      <h3 className="title-confirm">Email Verification</h3>
      <p className="subtitle-confirm">
        We have sent code to you email: <span>{email}</span> Please enter the
        code we sent.
      </p>
      <div className="ver-code">
        <ReactInputVerificationCode
          onCompleted={code => handleConfirmSignUp(code)}
          length={6}
          placeholder=""
        />
      </div>
      <p className="text-confirm">Didn't receive code?</p>
      <ButtonBase className="resend-confirm" onClick={handleResend}>
        <ResendIcon /> Resend code
      </ButtonBase>
    </Confirm>
  )
}

export default VerificationCode
