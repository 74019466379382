import React, { useState } from "react"
// import { RecoveryPassContainer } from "./RecoveryPassword.style"
import StepOneForm from "./StepOneForm"
import VerificationCode from "./StepTwoForm"
import NewPassword from './StepThreeForm'
const RecoveryPassword = ({ onCloseComp }) => {
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState(null)
  const [code, setCode] = useState(null)
  switch (step) {
    case 1:
      return (
        <StepOneForm
          onCloseComp={onCloseComp}
          setEmail={setEmail}
          setStep={val => setStep(val)}
        />
      )
    case 2:
      return (
        <VerificationCode
          setCode={val => setCode(val)}
          setStep={setStep}
          email={email}
        />
      )
    case 3:
      return (
        <NewPassword
          email={email}
          setStep={setStep}
          code={code}
          onCloseComp={onCloseComp}
        />
      )
  }
}

export default RecoveryPassword
