import { useState, useEffect } from "react"
import { navigate } from "gatsby"
import CustomHelmet from "@/components/CustomHelmet/CustomHelmet"
import Auth from "@/aws/AmplifyAuthConfigure"
import SignIn from "@/components/CourseForm/SignIn"
import SignUp from "@/components/CourseForm/SignUp"
import Loader from "@/components/Loader"
import ErrorBoundry from "@/components/ErrorBoundry/ErrorBoundry"
import { checkPayment } from "@/utils"

const Login = ({ location: { state } }) => {
  if (state === undefined) return null
  const [currentPage, setCurrentPage] = useState(null)
  const [loading, setLoading] = useState(false)
  const username = new URLSearchParams(location.search).get("username")
  const [error, setError] = useState(false)
  let contentPage = null
  // const helmet = username ? (
  //   <script type="text/javascript">
  //     {`
  //         {
  //           gtag('event', 'conversion', {
  //           'send_to': 'AW-369471881/OoX-CIPbpLEDEInjlrAB',
  //           'transaction_id': ''
  //           });
  //         }
  //       `}
  //   </script>
  // ) : null

  useEffect(async () => {
    setLoading(true)
    await Auth.currentSession()
      .then(session => {
        const jwtToken = session.getAccessToken().getJwtToken()
        if (jwtToken) navigate("/course")
      })
      .catch(async () => {
        if (username) {
          try {
            const res = await checkPayment(username).then(({ data }) =>
              JSON.parse(data.getStatusOfPayment)
            )
            if (res.statusCode !== 200) throw `${res.body}`
            setLoading(false)
            setCurrentPage("signup")
          } catch (e) {
            setLoading(false)
            setError(true)
          }
          return
        }
        setLoading(false)
        setCurrentPage("signin")
      })
  }, [])

  if (loading) return <Loader />
  if (error) return <ErrorBoundry />

  switch (currentPage) {
    case "signup":
      contentPage = (
        <SignUp username={username} nextStep={() => setCurrentPage("signin")} />
      )
      break
    case "signin":
      contentPage = <SignIn prevStep={() => setCurrentPage("signup")} />
      break
    default:
      contentPage = null
  }

  return (
    <>
      <CustomHelmet
        title="Login | Ronary app"
        canonical="https://ronary.com/login"
      >
        <meta
          name="description"
          content="Securely log in to your account Ronary app"
        />
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-369471881"
        ></script>
        <script type="text/javascript">
          {`
            window.dataLayer = window.dataLayer || []
            function gtag() {
              dataLayer.push(arguments)
            }
            gtag("js", new Date())
            gtag("config", "AW-369471881")
        `}
        </script>
        {helmet} */}
      </CustomHelmet>
      {contentPage}
    </>
  )
}

export default Login
