import { useState, useEffect } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import Auth from "@/aws/AmplifyAuthConfigure"
import TextField from "../FormFields/TextField"
import GoBackButton from "@/components/GoBackButton"
import { MyForm, ButtonSubmit } from "../CourseForm/auth.style"
const StepOneForm = ({ setEmail, setStep, onCloseComp }) => {
  const [err, setErr] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async ({ email }) => {
    setLoading(true)
    try {
      await Auth.forgotPassword(email)
      setLoading(false)
      setEmail(email)
      setStep(2)
    } catch (error) {
      setLoading(false)
      setErr(error.message)
    }
  }

  useEffect(() => {
    let seto
    if (err) {
      seto = setTimeout(() => {
        setErr(false)
      }, 5000)
    }
    return () => clearTimeout(seto)
  }, [err])

  return (
    <>
      <GoBackButton onClick={onCloseComp} className="goBackButton" />
      <p className="err-backend">{err && `\u002A ${err}`}</p>
      <h1 className="title">Forgot password</h1>
      <p className="sub-title" style={{ maxWidth: "427px" }}>
        Please, enter your email address. You will receive a code to create a
        new password via email.
      </p>

      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleSubmit }) => (
          <MyForm onSubmit={handleSubmit}>
            <div className="row" style={{ maxWidth: "290px" }}>
              <div className="row-item">
                <TextField
                  name="email"
                  value={values.email}
                  placeholder="Email"
                />
              </div>
            </div>

            <ButtonSubmit
              type="submit"
              disabled={loading}
              className={loading ? "loading" : ""}
            >
              Send
            </ButtonSubmit>
          </MyForm>
        )}
      </Formik>
    </>
  )
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email"),
})

export default StepOneForm
