import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { getMaskValidation } from "@/helpers"
import TextField from "../FormFields/TextField"
import PhoneFields from "../FormFields/PhoneField"
import ConfirmVerification from "../ConfirmVerification"
import GoBackButton from "@/components/GoBackButton"
import Auth from "@/aws/AmplifyAuthConfigure"
import { createUserUtils } from "@/utils"
// import { useLocalStorage } from "@/hooks/useLocalStorage"
import { FormContainer, MyForm, ButtonSubmit, ButtonNext } from "./auth.style"

const SignUp = ({ username, nextStep }) => {
  const [user, setUser] = useState(null)
  const [err, setErr] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async values => {
    setLoading(true)
    const { firstName, lastName, email, phone, promocode, password } = values
    if (username) {
      await handleAuthUser(email, password)
    } else {
      await handleCreateUserDB({ firstName, lastName, email, phone, promocode })
    }
  }

  const handleCreateUserDB = async values => {
    try {
      const res = await createUserUtils({ ...values }).then(({ data }) =>
        JSON.parse(data.createUserLead)
      )
      if (res.statusCode !== 200) throw `${res.body}`

      setLoading(false)
      window.location.replace(res.body)
    } catch (err) {
      setLoading(false)
      setErr(err)
    }
  }

  const handleAuthUser = async (email, password) => {
    try {
      await Auth.signUp({
        username: email,
        password,
      })

      // Edit email
      //
      setLoading(false)
      setUser({ email, password })
    } catch (e) {
      setLoading(false)
      setErr(e.message)
    }
  }

  useEffect(() => {
    let seto
    if (err) {
      seto = setTimeout(() => {
        setErr(false)
      }, 5000)
    }
    return () => clearTimeout(seto)
  }, [err])

  return (
    <FormContainer>
      {!username && (
        <GoBackButton onClick={() => history.back()} className="goBackButton" />
      )}
      <div className="inner">
        {user ? (
          <ConfirmVerification user={user} onBack={() => setUser(null)} />
        ) : (
          <>
            <p className="err-backend">{err && `\u002A ${err}`}</p>
            {username ? (
              <>
                <h1 className="title">Confirmation</h1>
                <p className="sub-title">
                  Please confirm your email address and enter your password
                </p>
              </>
            ) : (
              <>
                <h1 className="title">Sign up</h1>
                <p className="sub-title">
                  Already have an account?{" "}
                  <ButtonNext onClick={nextStep}>Sign In</ButtonNext>{" "}
                </p>
              </>
            )}

            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: username || "",
                phone: "",
                promocode: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={username ? validSchemaOne : validSchemaTwo}
              onSubmit={handleSubmit}
            >
              {({ values, handleSubmit }) => (
                <MyForm onSubmit={handleSubmit}>
                  {username ? (
                    <>
                      <div className="row">
                        <div
                          className="row-item"
                          style={{ width: "100%", maxWidth: "100%" }}
                        >
                          <TextField
                            name="email"
                            value={values.email}
                            placeholder="Email"
                            autoFocus
                            className="focus-input"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="row-item">
                          <TextField
                            name="password"
                            type="password"
                            value={values.password}
                            placeholder="Password"
                          />
                        </div>
                        <div className="row-item">
                          <TextField
                            name="confirmPassword"
                            type="password"
                            value={values.confirmPassword}
                            placeholder="Confirm password"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <div className="row-item">
                          <TextField
                            name="firstName"
                            value={values.firstName}
                            placeholder="First Name"
                          />
                        </div>
                        <div className="row-item">
                          <TextField
                            name="lastName"
                            value={values.lastName}
                            placeholder="Last Name"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="row-item">
                          <TextField
                            name="email"
                            value={values.email}
                            placeholder="Email"
                          />
                        </div>
                        <div className="row-item">
                          <PhoneFields
                            name="phone"
                            value={values.phone}
                            placeholder="Phone"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="row-item">
                          <TextField
                            type="text"
                            name="promocode"
                            maxLength="15"
                            value={values.promocode}
                            placeholder="Promo code (optional)"
                            id="data_promocode"
                          />
                        </div>
                        <div className="row-item"></div>
                      </div>
                    </>
                  )}
                  <ButtonSubmit
                    type="submit"
                    disabled={loading}
                    className={loading ? "loading" : ""}
                  >
                    {username ? "Confirm" : "Sign Up"}
                  </ButtonSubmit>
                </MyForm>
              )}
            </Formik>
          </>
        )}
      </div>
    </FormContainer>
  )
}

const validSchemaOne = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email"),
  password: Yup.string()
    .required("Password is required!")
    .matches(
      /^(?=.[a-z, A-Z])(?=.*[0-9])(?=.{8,})/,
      "Your Password Must Contain At Least 8 Characters Including Letters And Numbers"
    ),
  confirmPassword: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
})
const validSchemaTwo = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required!")
    .min(2, "First Name should be at least 2 chars length")
    .max(64, "First Name should be at most 64 chars length"),
  lastName: Yup.string()
    .required("Last Name is required!")
    .min(2, "Last Name should be at least 2 chars length")
    .max(64, "Last Name should be at most 64 chars length"),
  phone: getMaskValidation(11, "invalid phone number").required(
    "Phone is required!"
  ),
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email"),
})

export default SignUp
