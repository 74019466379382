import React from "react"
import { GoBackBtn } from "./GoBackButton.style"
import { ThinBigLeft } from "@/icons"
const GoBackButton = ({...props}) => {
  return (
    <GoBackBtn {...props}>
      <ThinBigLeft style={{ marginRight: `13px` }} /> Back
    </GoBackBtn>
  )
}

export default GoBackButton
