import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Formik } from "formik"
import * as Yup from "yup"
import Auth from "@/aws/AmplifyAuthConfigure"
import TextField from "../FormFields/TextField"
import ConfirmVerification from "../ConfirmVerification"
import RecoveryPassword from "../RecoveryPassword"
import GoBackButton from "@/components/GoBackButton"
import { FormContainer, MyForm, ButtonSubmit, ButtonNext } from "./auth.style"

const SignIn = ({ prevStep }) => {
  const [err, setErr] = useState(false)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [recover, setRecover] = useState(false)
  const handleResend = async (email, password) => {
    try {
      await Auth.resendSignUp(email)
      setUser({ email, password })
    } catch (error) {
      setErr(error.message)
    }
  }

  const handleSubmit = async ({ email, password }) => {
    setLoading(true)
    try {
      await Auth.signIn(email, password)
      navigate("/course")
    } catch (error) {
      setLoading(false)
      if (error.code === "UserNotConfirmedException") {
        handleResend(email, password)
        return
      }
      setErr(error.message)
    }
  }

  useEffect(() => {
    let seto
    if (err) {
      seto = setTimeout(() => {
        setErr(false)
      }, 5000)
    }
    return () => clearTimeout(seto)
  }, [err])

  if (recover) {
    return (
      <FormContainer>
        <div className="inner">
          <RecoveryPassword onCloseComp={()=>setRecover(false)}/>
        </div>
      </FormContainer>
    )
  }

  return (
    <FormContainer>
      <GoBackButton onClick={() => history.back()} className="goBackButton" />
      <div className="inner">
        {user ? (
          <ConfirmVerification user={user} onBack={() => setUser(null)} />
        ) : (
          <>
            <p className="err-backend">{err && `\u002A ${err}`}</p>
            <h1 className="title">Sign In</h1>
            <p className="sub-title">
              Don't have an account?{" "}
              <ButtonNext onClick={prevStep}>Sign up</ButtonNext>{" "}
            </p>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleSubmit }) => (
                <MyForm onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="row-item">
                      <TextField
                        name="email"
                        value={values.email}
                        placeholder="Email"
                      />
                    </div>
                    <div className="row-item">
                      <TextField
                        name="password"
                        type="password"
                        value={values.password}
                        placeholder="Password"
                      />
                    </div>
                  </div>

                  <ButtonSubmit
                    type="submit"
                    disabled={loading}
                    className={loading ? "loading" : ""}
                  >
                    Sign In
                  </ButtonSubmit>
                </MyForm>
              )}
            </Formik>
            <ButtonNext
              className="pass-forgot"
              onClick={() => setRecover(true)}
            >
              Forgot password?
            </ButtonNext>
          </>
        )}
      </div>
    </FormContainer>
  )
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email"),
  password: Yup.string()
    .required("Password is required!")
    .matches(
      /^(?=.[a-z, A-Z])(?=.*[0-9])(?=.{8,})/,
      "Your Password Must Contain At Least 8 Characters Including Letters And Numbers"
    ),
})

export default SignIn
