import styled from "styled-components"
import { ButtonBase } from "@/components/NotarizationButton"

export const FormContainer = styled.div`
  padding: 10rem 1.6rem;
  width: 100%;
  max-width: 632px;
  margin: 0 auto;
  font-wight: 400;
  color: #25272b;
  position: relative;

  .inner {
  }

  .err-backend {
    position: absolute;
    top: 6.6rem;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.025em;
    color: #ff5454;
  }
  .goBackButton {
    position: absolute;
    top: 2.5rem;
  }
  .title {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 96.84%;
    letter-spacing: -0.03em;
    color: #25272b;
  }
  .sub-title {
    max-width: 550px;
    margin-bottom: 2.5rem;
    font-size: 1.4rem;
    line-height: 130%;
  }
  .error-message {
    position: absolute;
    bottom: 6rem;
    left: 0;
    color: red;
  }

  .title-confirm {
    font-size: 24px;
    font-weight: 600;
    line-height: 96.84%;
    letter-spacing: -0.03em;
    margin-bottom: 1.4rem;
  }
  .subtitle-confirm {
    max-width: 433px;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }
  .text-confirm {
    font-size: 14px;
    line-height: 130%;
    color: #494949;
    margin-bottom: 8px;
  }
  .resend-confirm {
    font-size: 15px;
    font-weight: 600;
    line-height: 130%;
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.8rem;
    }
  }

  @media (min-width: 480px) {
    padding-left: 24px;
    padding-right: 24px;
    .title {
      font-size: 3.1rem;
    }
  }

  @media (min-width: 768px) {
    .title-confirm {
      font-size: 30px;
      margin-bottom: 2rem;
    }
    .subtitle-confirm {
      margin-bottom: 60px;
      font-size: 15.5px;
    }
  }

  @media (min-width: 1025px) {
    padding: 150px 0;
    .title {
      font-size: 4rem;
      margin-bottom: 2.5rem;
    }
    .sub-title {
      font-size: 1.7rem;
      margin-bottom: 4.3rem;
    }
    .err-backend {
      top: 10.5rem;
    }

    .goBackButton {
      top: 5rem;
    }

    .title-confirm {
      font-size: 40px;
      margin-bottom: 2.5rem;
    }
    .subtitle-confirm {
      font-size: 17px;
      margin-bottom: 90px;
    }
    .text-confirm {
      font-size: 15px;
      margin-bottom: 14px;
    }
    .resend-confirm {
      font-size: 17px;
    }
  }
`

export const MyForm = styled.form`
  display: flex;
  flex-direction: column;

  .row-item {
    margin-bottom: 2.5rem;
  }
  input {
    font-family: "SF Pro Display Redular";
    padding: 1.5rem 0;
  }
  input.focus-input {
    caret-color: #ffc700;
    background-color: #f1f3f9;
  }

  @media only screen and (min-width: 480px) {
    .row {
      display: flex;
      gap: 5rem;
    }
    .row-item:nth-child(even) {
      /* margin-left: 2rem; */
    }
    .row-item {
      /* flex-grow: 1; */
      width: 50%;
      max-width: 29rem;
      margin-bottom: 4rem;
    }
  }
`

export const ButtonSubmit = styled(ButtonBase)`
  width: 100%;
  margin-top: 2.5rem;
  padding: 1.5rem 0;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  background-color: #25272b;
  color: #fff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04);

  &.loading {
    background-color: #c4c4c4;
  }

  @media (min-width: 480px) {
    width: 290px;
    font-size: 1.6rem;
  }
`

export const ButtonNext = styled(ButtonBase)`
  font-size: 1.7rem;
  font-weight: 600;
  border-bottom: 1px solid #25272b;
  line-height: 1.7rem;
  letter-spacing: -0.025em;

  &.pass-forgot {
    font-weight: 500;
    margin-top: 3.5rem;

    @media (min-width: 768px) {
      margin-top: 5rem;
    }

    @media (min-width: 1025px) {
      margin-top: 10rem;
    }
  }
`
