import { useState, useEffect } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import Auth from "@/aws/AmplifyAuthConfigure"
import GoBackButton from "@/components/GoBackButton"
import TextField from "../FormFields/TextField"
import { MyForm, ButtonSubmit } from "../CourseForm/auth.style"

const NewPassword = ({ email, code, onCloseComp, setStep }) => {
  const [err, setErr] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async ({ password }) => {
    setLoading(true)
    try {
      await Auth.forgotPasswordSubmit(email, code, password)
      setLoading(false)
      onCloseComp(false)
    } catch (error) {
      setLoading(false)
      setErr(error.message)
    }
  }

  useEffect(() => {
    let seto
    if (err) {
      seto = setTimeout(() => {
        setErr(false)
      }, 5000)
    }
    return () => clearTimeout(seto)
  }, [err])

  return (
    <div className="inner">
      <GoBackButton onClick={() => setStep(2)} className="goBackButton" />
      <p className="err-backend">{err && `\u002A ${err}`}</p>
      <h1 className="title">Create new password</h1>
      <p className="sub-title">Please create a secure password</p>

      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleSubmit }) => (
          <MyForm onSubmit={handleSubmit}>
            <div className="row">
              <div className="row-item">
                <TextField
                  name="password"
                  type="password"
                  value={values.password}
                  placeholder="Password"
                />
              </div>
              <div className="row-item">
                <TextField
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  placeholder="Confirm password"
                />
              </div>
            </div>

            <ButtonSubmit
              type="submit"
              disabled={loading}
              className={loading ? "loading" : ""}
            >
              Save
            </ButtonSubmit>
          </MyForm>
        )}
      </Formik>
    </div>
  )
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password should contains at least 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 symbol"
    ),
  confirmPassword: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
})

export default NewPassword
